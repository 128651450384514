@import "../../atlas/prerequisite";
@import "../styles.scss";
@import "src/assets/stylesheets/new_atlas/index.scss";

.at-form-input__help_text {
  background: transparent;
  color: #4b5563;
  left: 0px;
  right: auto;
  position: absolute;
  padding: 4px 10px 4px 0px;
  top: 0px;
  font-size: 14px;
}

.at-form-input__instruction {
  position: absolute;
  left: 0px;
  font-size: 12px;
  line-height: 150%;
  color: #4b5563;
}

.at-select {
  position: relative;
  display: inline-block;
  width: 100%;

  &.responsive {
    max-width: 750px;
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}

.at-select__chevron {
  color: #000000 !important;
  position: absolute !important;
  right: 10px !important;
  font-size: 23px !important;
  transform: rotate(270deg) !important;
  transition: transform 0.3s ease-in-out !important;
}

.at-select__chevron.expand {
  transform: rotate(450deg) !important;
}

.at-select__selected-item {
  width: 100% !important;
  height: 41px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  padding-left: 10px !important;
  border: 1px solid #9ca3af !important;
  cursor: pointer !important;
  background-color: #ffffff !important;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease !important;

  &::before,
  &::after {
    border-bottom: 0px !important;
  }
  &:focus-within:not(.at-form-input--disabled) {
    border-color: #3b82f6;
    box-shadow: 0px 0px 0px 2px #3b82f6;
  }
}

.at-select__selected-item-disabled {
  background-color: #f5f5f51a !important;
  cursor: not-allowed !important;

  .at-select__selected-item-select {
    background-color: transparent !important;
    color: #4b5563 !important;
  }
}

.at-select__selected-item.expand {
  background-color: #ffffff !important;
  border: 1px solid #d1d5db !important;
  box-shadow: 0px 0px 0px 2px #d1d5db !important;
}

.at-select--disabled .at-select__selected-item {
  background-color: $gray-100 !important;
  border: 1px solid $gray-300 !important;
  cursor: not-allowed !important;
  color: $gray-300 !important;
  font-weight: 500 !important;
  padding-left: 15px !important;
  color: $gray-700 !important;
}

.at-select--error .at-select__selected-item {
  border: 2px solid #d93335 !important;
}

.at-select__selected-item-select {
  background: #ffffff !important;
  width: 100% !important;
  font-size: 14px !important;
  padding-left: 0px !important;

  .at-select--disabled & {
    cursor: not-allowed !important;
    background-color: #f5f5f51a !important;
  }
}

.at-select__selected-item-select.expand {
  border-radius: 2px !important;
  background-color: #ffffff !important;
}

.at-select__dropdown-paper {
  width: 220px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
  overflow: auto !important;
  transition:
    height 0.3s ease,
    opacity 0.3s ease,
    transform 0.3s ease !important;
  @media screen and (min-height: 600px) {
    .at-select__dropdown-paper {
      max-height: 300px !important;
    }
  }

  .at-select__dropdown-paper-list {
    z-index: 4 !important;
    position: relative !important;
    width: 100% !important;
    background-color: white !important;
    border-radius: 5px !important;
    top: 0px !important;
    left: 0px !important;
    overflow: auto !important;
    padding: 5px !important;
    box-shadow:
      0 10px 20px rgba(0, 0, 0, 0.19),
      0 6px 6px rgba(0, 0, 0, 0.23) !important;
    flex-wrap: wrap !important;
  }

  .at-select__dropdown-paper-list.expand {
    transform: translateY(50px) !important;
    opacity: 1 !important;
  }
}

.at-select__dropdown-item {
  height: 48px !important;
  background: inherit !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 0 0 10px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  border-radius: 3px !important;
}

.at-select__dropdown-item:hover {
  background-color: #e0e0e0 !important;
}

.at-select__dropdown-item:last-of-type {
  border: 0px !important;
}

@media screen and (min-width: 600px) {
  .at-select__dropdown-item {
    font-size: 14px !important;
  }
}

.at-select__dropdown-title {
  background: #ffffff !important;
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 0 0 10px !important;
  font-size: 14px !important;
  width: 100% !important;
  font-weight: 500 !important;
  margin-bottom: 2px !important;
  border-bottom: 1px solid #d1d5db;
}

.at-select__search-cont {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  background: white;
  border: 1px solid #d1d5db;
  border-radius: 8px;

  @media (min-width: 992px) {
    .at-table-head & {
      width: calc(100% - 2rem);
      margin: 1rem 0px 1rem 1rem;
    }
  }

  &:focus-within,
  &:has(.MuiInput-focused-839) {
    box-shadow: 0px 0px 0px 2px #3b82f6;
  }

  @media (max-width: 991px) {
    margin: 1rem 0rem 1rem;
  }

  @media (max-width: 499px) {
    width: 100%;
  }
}

.at-select_new-interface {
  width: 10vw !important;
  min-width: 120px !important;
  overflow: hidden;
  margin: 0 !important;

  &.at-select--disabled {
    .at-select__selected-item {
      background-color: #f3f4f6 !important;
    }

    & + .at-table__search-input {
      background-color: #f3f4f6 !important;
    }
  }

  .at-select__selected-item {
    width: 10vw !important;
    min-width: 120px !important;
    border-left: 0px !important;
    border-bottom: 0px !important;
    border-top: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: #ffffff !important;
  }
  .at-select__selected-item-select {
    width: 10vw !important;
    min-width: 70px !important;
    background: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #1f2937 !important;
  }

  @include min-screen(600px) {
    width: 200px;
  }

  @include min-screen($sidebar-breakpoint) {
    margin-bottom: 0 !important;

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}

.at-select__selected-placeholder,
.at-select--disabled .at-select__selected-placeholder {
  color: #6B7280 !important;
}