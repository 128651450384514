.at-input_uploader-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;

  label.at-input_upload-label {
    font-size: 14px;
    font-weight: 500;
    color: #1f2937;

    .at-input_upload-highlight {
      font-size: 14px;
      font-weight: 500;
      color: #2563eb;
    }
  }

  svg {
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 50%;
    fill: #3b82f6;
    background-color: #dbeafe;
  }

  .at-input_upload-file {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.custom-excel_upload {
  .at-input_upload-file {
    opacity: 1;
  }

  .filepond--drop-label {
    position: absolute;
    padding-top: 4rem;
    height: 7rem;
    font-size: 14px;
    cursor: pointer;

    label {
      font-weight: 500;
    }

    label::before {
      font-size: 14px;
      content: "Click to upload";
      color: #2563eb;
    }

    label::after {
      font-size: 14px;
      content: "XLSX, XLS or CSV";
    }
  }
}

.at-input_upload-cont.custom-image_upload {
  .at-input_upload-file {
    opacity: 1;
  }
}

.at-report_new_sales-file_upload {
  .filepond--drop-label {
    transform: translate3d(0px, -10px, 0px) !important;
  }

  .at-input_uploader-cont svg {
    margin: 15px auto;
  }
}

.at-file_item-files_cont {
  display: flex;
  width: 100%;

  .at-file_item-cont {
    height: 65px;
    display: flex;
    background-color: #374151;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    .at-file_item-overlay {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 50px 0px #0000007d;
    }

    img {
      height: 100%;
      margin: auto;
      background-size: 100%;
      width: 100%;
      object-fit: contain;
    }

    .at-file_item-action {
      width: 100%;
      position: absolute;
      display: flex;
      padding: 14px;
      transition: all 0.2s ease-out;

      &:hover {
        background-image: linear-gradient(#00000057 30%, transparent 130%);
      }

      p {
        color: #fff;
        text-align: start;
      }
      .at-file_item-action-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        top: 0;
        left: 0;
        opacity: 0;
        mix-blend-mode: overlay;

        &:hover {
          opacity: 1;
          background-image: linear-gradient(#000000fa 0%, transparent 110%);
          transition: all 0.2s ease-out;
        }
      }
      .at-file_item-btn_close {
        z-index: 1;
        align-self: center;
        width: 30px;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
        margin-right: 0.5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in;

        &:hover {
          border: 2px solid #fff;
        }

        svg {
          fill: #fff;
          width: 20px;
          height: 20px;
        }
      }

      .at-file_item-file_name {
        white-space: nowrap;
        overflow: hidden;
        font-weight: 500;
        font-size: 14px;
        text-overflow: ellipsis;
      }
    }
  }
}

.at-form-input_upload {
  position: relative;

  .filepond--root.filepond--hopper {
    cursor: pointer;
    height: 134px !important;
  }

  svg {
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 50%;
    fill: #3b82f6;
    background-color: #dbeafe;
    position: absolute;
    top: 16px;
    left: calc(50% - 24px);
    z-index: 1;
  }

  .filepond--drop-label {
    border-radius: 8px;
    border: 1px dashed #d1d5db;
    background: #f9fafb;
    color: #2563eb;
    height: 134px;

    > label {
      margin-top: 36px;
      font-size: 14px;
      width: 280px;
    }
  }

  .filepond--credits {
    display: none;
  }
}