@import "../../atlas/prerequisite";
@import "../styles.scss";
@import "src/assets/stylesheets/new_atlas/index.scss";

.at-form-input {
  border-radius: 8px;
  border: 1px solid #9ca3af;
  background: white;
  padding: 3px 10px;
  position: relative;
  display: flex;
  align-items: center;
  transition: border-color 0.2s ease;

  .el-input__icon.el-icon-date {
    display: none;
  }
  &:not(.textarea) {
    height: 41px;
  }

  &:focus-within:not(.at-form-input--disabled) {
    border-color: #3b82f6;
    box-shadow: 0px 0px 0px 2px #3b82f6;
  }

  &:focus-within {
    box-shadow: 0px 0px 0px 2px #3b82f6;
  }

  div:has(.react-datepicker__input-container) {
    width: 100%;
    cursor: pointer;
  }

  .rbt-input-hint-container {
    width: 100%;
  }

  &.claim_form-disabled {
    background-color: #f3f4f6 !important;
  }
}

.react-datepicker__year-dropdown-container {
  .react-datepicker__navigation--years-upcoming,
  .react-datepicker__navigation--years-previous {
    text-indent: 0;
  }

  .react-datepicker__navigation--years-upcoming::after {
    content: "\2191";
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .react-datepicker__navigation--years-previous::after {
    content: "\2193";
  }
}

.react-datepicker-wrapper.at-form-input__input {
  display: flex;
  order: 0;

  .react-datepicker__input-container {
    margin: auto;
  }

  input {
    width: 100%;
    text-align: left;
  }
}

.at-form-input__search-icon {
  margin-right: 5px;
  fill: $grey-3;
  order: 1;
  width: 20px;
  height: 20px;
  transition: fill 0.2s ease;
}

.at-form-input__close-icon {
  margin-right: 5px;
  fill: $grey-3;
  order: 3;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: fill 0.2s ease;
}

.at-form-input__textarea {
  border: none;
  padding: 0px 5px;
  height: 120px;
  margin: 10px 0px;
  font-size: 14px;
}

.at-form-input__title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #1f2937;

  &[required]::before {
    content: "*";
    color: $valencia;
    margin-right: 2px;
  }
}

.at-new_header-title-1 {
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%
}

.at-new_header-title-2 {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%
}

.at-new_header-title-3 {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%
}

.at-new_typograph {
  color: #1F2937;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.at-form-info__title {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%
}

.at-form-input__input {
  height: 32px;
  background-color: transparent;
  order: 2;
  font-size: 14px;
  color: black;
  width: 100%;
  padding-left: 5px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &.at-form-input--error::placeholder {
    color: $valencia;
  }
  &.at-form-input--disabled::placeholder {
    background-color: $gray-700;
    color: #d1d5db;
    @include at-font(500);
  }

  &:disabled {
    color: $gray-700;
    @include at-font(500);
    cursor: no-drop;
  }

  &::placeholder {
    color: #6B7280;
    transition: color 0.3s ease;
  }

  &:focus {
    &::placeholder {
      color: #6b728080;
    }

    ~ .at-form-input__line::after {
      transform: scaleX(1);
      background-color: $tangerine;
    }

    ~ .at-form-input__search-icon {
      fill: $tangerine;
    }
  }
}

.at-form-input__disabled {
  background-color: $gray-100;
  color: $gray-700;
  border: 1px solid $gray-300;
  @include at-font(500);
  cursor: not-allowed;

  input {
    color: $gray-700 !important;
  }
}

.at-form-input .at-form-input__disabled {
  position: absolute;
  bottom: -17px;
  right: 6px;
  background-color: $gray-100;
  color: $gray-700;
  border: 1px solid $gray-300;
  @include at-font(500);
}

.at-form-input--error:has(input:not(:focus)) {
  box-shadow: 0px 0px 0px 2px #f04438;
  background-color: rgba($valencia, 0.1) !important;

  .at-select__selected-item-select {
    background-color: transparent !important;
  }
}

.at-input_footer {
  position: relative;
  width: 100%;

  @media (max-width: 779px) {
    &.at-input_footer-responsive {
      margin-bottom: 3rem !important;
    }
  }
}

.at-form-input__required {
  position: absolute;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  bottom: -15px;
  right: 6px;
  color: $grey-3;
}

.at-form-typeahead__required {
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  color: $grey-3;
  padding-right: 8px;
  position: absolute;
  right: 0px;
  bottom: -17px;
}
