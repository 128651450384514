@import "../../../../../stylesheets/atlas/prerequisite";
@import "src/assets/stylesheets/new_atlas/index.scss";

.form-action {
  display: flex;
  flex-flow: row wrap;

  @include max-screen(630px) {
    flex-direction: column;
  }
}

.at-party__dropbear--modal-root {
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 5px 5px #0000000d;
  position: absolute !important;
  margin-top: 5px;
  padding-bottom: 5px;
  min-width: 300px;
  height: fit-content;
  top: 40px;
  left: 0px;
  z-index: 3;
  border: 1px solid $gray-300;

  @media screen and (max-width: 767px) {
    width: 300px;
    transform: translateX(-208px);
  }
}

.at-party__dropbear--list {
  font-size: 14px;

  button {
    padding: 10px;
    margin-top: 5px;
    text-align: center;
    display: block;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: $grey-1;
    }

    .selected {
      background-color: $grey-1;
    }
  }
}

.refund-party-container {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 20px;
  background: white;
  margin-bottom: 10px;

  .refund-party-member {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;

    .refund-party-member-label {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
      text-transform: capitalize;
    }
  }
}

.refund-letter-input {
  width: 150px;
  max-width: 400px;
  height: 50px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;

  :disabled {
    background: #ececec;
  }
}

.at-form-switch_button {
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
  width: max-content;
  grid-column: 1;

  @media (max-width: 767px) {
    grid-column: 1;
    grid-row: 1;
  }
  @media (max-width: 575px) {
    grid-column: 1;
    grid-row: 1;
  }
  @media (max-width: 299px) {
    grid-column: 1/-1;
    grid-row: 2;
  }

  &.disabled {
    label {
      cursor: no-drop;
    }
  }

  button {
    display: flex;
    align-self: center;
    width: 11rem;
    cursor: initial;

    input[type="checkbox"] {
      position: absolute;
      visibility: hidden;
      transition: background-color 0.25s;
    }

    label {
      display: flex;
      width: 2rem;
      height: 20px;
      background-color: #D1D5DB;
      border-radius: 99em;
      position: relative;
      transition:
        transform 0.5s ease-in-out,
        background-color 0.25s;
      transform-origin: 50% 50%;
      cursor: pointer;

      &:before {
        transition: transform 0.5s ease;
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #ffffff;
        border-radius: 50%;
        top: calc(50% - 8px);
        left: calc(50% - 14px);
      }
    }
    span {
      margin: auto 0.5rem;
      color: #38474f;
      font-weight: 500;
    }
  }

  &.checked {
    button {
      input[type="checkbox"] {
        & + label {
          background-color: #3B82F6;
          &:before {
            transform: translateX(0.7rem);
          }
        }
      }
    }
  }
}

.at-label_icon-cont {
  @media (min-width:1400px) {
    position: absolute;
    top: -0.1rem;
  }

  svg {
    margin-bottom: -0.3rem;
    vertical-align: text-top;
  }

  span,
  svg {
    margin-left: 0.5rem;
  }
}

.at-form__draggable-icon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  color: #2323b7;
  margin-right: 8px;
  padding: 5px;
  background: white;
  border-radius: 5px;
}

.at-form__confirmation {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h4 {
    color: #1f2937;
    font-weight: 600;
    line-height: 130%;
  }

  p {
    color: #1f2937;
    line-height: 150%;
  }
}

.at-form__participant-list_cont {
  &:not(:last-child) {
    border-bottom: 1px solid #dadada;
  }
}

.at-form_participants-cont {
  > div {
    padding: 0 !important;
  }

  .at-form_participants-list {
    > div {
      padding-left: 10rem;
      margin-bottom: 0px !important;
    }
  }
}

.at-form__participant-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;

  > div {
    display: flex;
    align-items: start;
    grid-gap: 0.5rem;
    padding: 0 !important;
    margin: 0 !important;
    flex: 0 0 calc(25% - 12px);
    min-height: 40px;
    max-width: calc(25% - 12px);

    @media (max-width: 991px) {
      flex: 0 0 calc(50% - 12px);
      max-width: calc(50% - 12px);
    }
    @media (max-width: 767px) {
      flex: 0 0 calc(100% - 12px);
      max-width: calc(100% - 12px);
    }
  }

  svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
    fill: #9ca3af;
    margin-right: 8px;
    align-self: flex-start;
    margin-top: 0.4rem;
  }

  label {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  p {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.at-form__sign-party-title {
  padding: 0px 0px 16px;
  font-size: 14px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 8px;
  border-bottom: 1px solid #d1d5db;
}

.at-form__signature-box-input {
  width: 40px;
  text-align: center;
  padding: 0px;
  height: 40px;
}

.at-form_mngmt__inventory-table {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  .at-table-row__item {
    padding: 12px 0px 12px 16px !important;

    &:first-child {
      padding-left: 12px !important;
    }

    &:last-child {
      padding-right: 12px !important;
    }
  }

  .at-table__height-control {
    min-height: fit-content;
  }

  &.admin {
    > .at-table__options .at-table-head {
      border-radius: 0;
    }
    > .at-table__options:last-child .at-table-row-cont:last-child {
      border-radius: 0 0 0.6rem 0.6rem;
    }
  }

  .at-table-row__item.at-table__actions {
    .at-table-icon-button {
      width: 35px;
      height: 35px;
    }
  }

  &:not(.at-form_mngmt__inventory-disabled):not(.admin) {
    .at-table__options .at-table .at-table-row-cont:last-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0px;
    }
  }

  .at-table-row-cont.at-form_mngmt__inventory-action_footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .at-table-row__item {
      min-height: initial;
      padding: 0;
    }
  }

  @media (max-width: 1199px) {
    .at-table__options {
      .at-table-row-cont {
        .at-table-row.grid-controller {
          .at-table-row__item {
            display: grid;
            grid-template-columns: 100%;
          }
          .at-custom-column {
            margin: 0;
            width: 100% !important;
          }
          .at-table-row__item.at-table__actions {
            align-items: end !important;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .at-table__options {
      .at-table-row-cont {
        margin-bottom: 0px;
        &:not(:last-of-type) {
          border-bottom: 0px;
        }

        .at-table-row.grid-controller {
          padding: 16px 16px 12px 16px;
          grid-gap: 12px !important;

          .at-table-row__item {
            margin-top: 0 !important;
            padding: 0 !important;
          }

          .at-table__actions {
            display: none !important;
          }
        }
      }

      .at-table-row__item-label {
        position: relative;
        display: block;
        font-size: 14px;
      }

      .at-table-row__mobile-action {
        display: flex;
        justify-content: space-evenly;
        border-top: 1px solid #e5e7eb;
        margin: 0px 20px;
        padding: 14px 10px;
      }
    }

    .at-table-row-cont {
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      &:not(:last-of-type) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .at-form_mngmt__inventory-content {
      margin-bottom: 0;
    }

    .at-table-row-cont.at-form_mngmt__inventory-action_footer {
      .at-table-row {
        padding: 0;
      }
    }
  }

  @media screen and (min-width: 991px) {
    .at-table-row-cont:nth-child(2n):not(:hover) {
      background-color: #ffffff;
    }
  }
}

.at-form_mngmt__inventory-header {
  align-items: center;
  background: #4b5563;
  border: 1px solid #dbe2e6;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #fff;
  display: flex;
  height: 65px;
  padding: 12px;
  grid-gap: 16px;

  ~.at-table__options  .at-table-head {
    border-radius: 0;

    &:last-child {
      border-radius: 0rem 0rem 8px 8px;
    }
  }

  .at-form_mngmt__inventory-content {
    display: flex;
    align-items: center;
    background: #6b7280;
    border: 1px solid #6b7280;
    margin: 0px;

    input {
      color: white;
      width: 100%;
      background: none;
      font-weight: 600;
      font-size: 16px;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: #d1d5db;
    }
  }

  svg {
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }
}

.at-form_mngmt__table-header,
.at-form_mngmt__inventory-table-header {
  background: $white;
  color: black;
}

.at-form_mngmt__table-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.at-table-inventory_table-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 1rem;
  border: 1px solid #dbe2e6;
  transition:
  background-color 0.3s ease,
  transform 0.3s ease;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px 18px;
  margin-bottom: 1rem;

  &[showItems="false"] {
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }

  .at-table-inventory_table-heading_icon {
    display: flex;
    width: 35px;
    height: 35px;
    background-color: #ffedd5;
    color: #f0631e;
    fill: #f0631e;
    padding: 0.5rem;
    border-radius: 50%;
  }
}

.at-form_mngmt__inventory-content {
  width: 100%;
  height: 45px;
  background: white;
  margin: 5px 0px;
  border-radius: 10px;
  padding: 12px 14px;
  border: 1px solid #d1d5db;
  grid-gap: 8px;
}

.at-form_mngmt__inventory-content-button {
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
  background-color: #3b82f6;
  margin: 5px 0px;
  border-radius: 10px;
  padding: 10px;

  &:disabled {
    opacity: 0.5;
  }
}

.at-form_mngmt__request-card {
  background: #ffffff;
  border: 1px solid #d1d5db;
  margin-bottom: 16px;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.08),
    0px 2px 1px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1) !important;
  border-radius: 8px !important;

  &p {
    color: #374151;
  }
}

.at-eSigningProcess-participant_icon {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  fill: #9ca3af;
  color: #9ca3af;

  &.user_icon {
    fill: #f0631c;
    color: #f0631c;
  }
}

.at-party_table-row-cont {
  @media (max-width: 991px) {
    border: 0px !important;
    background-color: transparent;
    box-shadow: initial;

    .at-table-row.grid-controller {
      padding: 0;
    }

    .at-table-row__item {
      padding: 0;
    }
  }
}

.at-form-tnc {
  .subsales-letter-input {
    margin: 0 0.5rem;
  }
}

.at-tenancy-agreement_partcipants {
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  > div:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #d1d5db;
  }
}

.at-confirmation_form-tenancy-checkbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    text-wrap: wrap;
  }
}