@import "../../stylesheets/atlas/prerequisite";
@import "src/assets/stylesheets/new_atlas/index.scss";

.at-text_area {
  background-color: #ffffff;
  border: 1px solid #9ca3af;
  border-radius: 8px;
  height: calc(100% - 54px);
  padding: 10px 14px;
  min-height: 100px;
  margin-bottom: 0px;
  font-size: 14px;

  &:focus-within:not(:disabled) {
    border-color: #3b82f6;
    box-shadow: 0px 0px 0px 2px #3b82f6;
  }

  &:disabled {
    background-color: #f3f4f6;
    color: $gray-700;
    border: 1px solid #d1d5db;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px #3b82f6;
  }

  &.input-error {
    background-color: #fef3f2;
    box-shadow: 0px 0px 0px 2px #f04438;
  }
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
  display: block;
}

.at-input_uploader-cont {
  background-color: #fff;
  padding: 16px 24px;
  position: relative;
  border: 1px dashed #d1d5db;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  min-height: 7.5rem;

  input {
    cursor: pointer;
  }

  svg {
    width: 56px;
    height: 56px;
    display: block;
    margin: auto;
    transform: translate(0, -19px);
  }

  .filepond--wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .filepond--drop-label {
    position: absolute;
    padding-top: 4rem;
    height: 8rem;
    font-size: 14px;
    cursor: pointer;

    > label {
      margin-top: 36px;
      font-size: 14px;
      width: 280px;
    }

    label::before {
      font-size: 14px;
      font-weight: 500;
      content: "Click to upload";
      color: #2563eb;
    }

    label::after {
      font-size: 14px;
      content: "PNG, JPG or JPEG";
    }
  }

  .filepond--panel-root {
    background-color: transparent;
    opacity: 0;
  }

  .filepond--credits {
    display: none;
  }

  .filepond--list-scroller {
    display: none;
  }

  .filepond--item {
    margin: 0;
  }

  .filepond--list.filepond--list {
    position: static;
  }

  .filepond--image-preview-overlay.filepond--image-preview-overlay-idle {
    rect {
      display: none;
    }
  }

  .filepond--root.at-file_upload.filepond--hopper {
    padding-bottom: 3rem;
  }

  .filepond--panel.filepond--panel-root,
  .filepond--root.at-file_upload.filepond--hopper {
    height: 100% !important;
  }
}

.at-image_upload_items_list-cont {
  display: flex;
  width: 100%;

  .at-image_upload_item-cont {
    display: flex;
    background-color: #111827;
    flex: 0 0 50%;
    min-width: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    .at-image_upload_item-overlay {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 50px 0px #0000007d;
    }

    img {
      height: 100%;
      margin: auto;
      background-size: 100%;
      width: 100%;
      object-fit: contain;
    }

    .at-image_upload_item-action {
      width: 100%;
      position: absolute;
      display: flex;
      padding: 14px;
      transition: all 0.2s ease-out;

      p {
        color: #fff;
      }
      .at-image_upload_item-action-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        top: 0;
        left: 0;
        opacity: 0;
        mix-blend-mode: soft-light;

        &:hover {
          opacity: 1;
          background-image: linear-gradient(#000, transparent);
          transition: all 0.2s ease-out;
        }
      }
      .at-image_upload_item-btn_close {
        z-index: 1;
        min-width: 30px;
        min-height: 30px;
        margin-right: 0.5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in;

        &:hover {
          border: 2px solid #fff;
        }

        svg {
          fill: #fff;
          width: 20px;
          height: 20px;
        }
      }

      .at-image_upload_item-file_name {
        width: 100%;
        max-width: 300px;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.at-custom-datetimepicker-root.at-form-input {
  flex-direction: row;

  &,
  & > div {
    width: 100%;
    height: 32px;
  }
  > div::before {
    border-bottom: initial;
  }
  input {
    font-size: 14px;
    text-align: left;
    padding: 0.6rem 0rem;
  }
  fieldset {
    border: #ffffff;
  }

  &.disabled {
    background: #f3f4f6;
  }
}

.at-custom-datetimepicker-root.at-search-form-input {
  flex-direction: row;

  &,
  & > div {
    width: 100%;
    height: 30px;
  }
  > div::before {
    border-bottom: initial;
  }
  input {
    padding: 0.6rem 0rem;
  }
  fieldset {
    border: #ffffff;
  }
}

div.at-custom-datetimepicker-paper_dialog {
  width: 100%;

  @media (max-width: 449px) {
    margin: 0;
  }

  > div {
    margin: 1rem;

    @media (max-width: 449px) {
      margin: 0;
    }
  }
}

div.at-custom-datetimepicker-root {
  width: 100%;
  align-items: start;
  justify-content: start;
  > div {
    width: 90%;
    &::before {
      border: initial;
    }
  }
  input {
    color: #000000;
  }
}

.at-custom-datepicker-cont {
  width: 100%;
  border: none;
  padding: 0;
}

.at-custom-datepicker-cont__disabled {
  background-color: $gray-100;
  color: $gray-700;
  @include at-font(500);
  cursor: not-allowed;

  input {
    color: $gray-700 !important;
  }
}

.at-datetimepicker-clear_btn-cont {
  width: 30px;
  height: 20px;
  cursor: pointer;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
  color: #FFFFFF;
  display: flex;
  border-left: 1px solid #D1D5DB;

  .at-datetimepicker-clear_btn {
    width: 18px;
    height: 18px;
    color: #3F4448;
    margin: auto;
  }
}
